import "./index.css";
import "./style.css";
import "./images";
import "./spreadsheets";

import { Elm } from "./Main.elm";
import io from "socket.io-client";
import Sortable from 'sortablejs';

// const alreadyOpened = localStorage.opened !== undefined
// if (alreadyOpened) {
//   window.close()
//   alert("Outra aba já está aberta!")
// }

const storedUser = localStorage.getItem('session')
const flags = storedUser ? JSON.parse(storedUser) : null;
localStorage.opened = true
window.addEventListener("beforeunload", event => {
  localStorage.removeItem("opened")
})

const app = Elm.Main.init({
  node: document.getElementById("app"),
  flags,
});

let socket
let hsmByPass
let hsmSocket
let event_issue_card
let event_update_card
let event_apply_refill
let event_blockunblock_request

const initSockets = () => {
  try {
    socket = io("http://localhost:8080", { forceNew: true, timeout: 10000 })
    hsmByPass = io("http://localhost:8081", { forceNew: true, timeout: 10000 })
    hsmByPass.on("hsm-by-pass", function(msg, sendAckCb) {
      msg = JSON.parse(msg)
      hsmSocket.emit(msg.event, JSON.stringify(msg.data), function(data) {
        sendAckCb(JSON.stringify(JSON.parse(data)));
      })
    })
    const session = localStorage.getItem('session')
    if (session)
      hsmSocket = io("https://websocket.hsm.dev.pazze.app",
        { forceNew: true
        , timeout: 10000
        , query: { auth: JSON.parse(session).token }
        })
  } catch (e) {
    console.log("Socket init error", e)
  }
}

const issueCardFn = request => {
  console.log("send contactlessStockIssue", request)
  socket && socket.emit("contactlessStockIssue", JSON.stringify(request), ack => {
    console.log("ack contactlessStockIssue", ack)
    const payload = JSON.parse(ack) 
    clearTimeout(event_issue_card)
    app.ports.issueCardRecv.send(payload)
  })
  event_issue_card = setTimeout(() => {
    app.ports.issueCardRecv.send({ error: "Não foi possível se comunicar com o plugin." })
  }, 10000)
}

const updateCardFn = request => {
  console.log("send contactlessUpdate", request)
  socket && socket.emit("contactlessUpdate", JSON.stringify(request), ack => {
    console.log("ack contactlessUpdate", ack)
    const payload = JSON.parse(ack)
    clearTimeout(event_update_card)
    app.ports.updateCardRecv.send(payload)
  })
  event_update_card = setTimeout(() => {
    app.ports.updateCardRecv.send({ error: "Não foi possível se comunicar com o plugin." })
  }, 10000)
}

const applyRefillFn = request => {
  console.log("send contactlessCredit", request)
  socket && socket.emit("contactlessCredit", JSON.stringify(request), ack => {
    console.log("ack contactlessCredit", ack)
    const payload = JSON.parse(ack) 
    clearTimeout(event_apply_refill)
    app.ports.applyRefillRecv.send(payload)
  })
  event_apply_refill = setTimeout(() => {
    app.ports.issueCardRecv.send({ error: "Não foi possível se comunicar com o plugin." })
  }, 10000)
}

const applyBlockUnblockRequestFn = request => {
  console.log("send contactlessBlockUnblock", request)
  socket && socket.emit("contactlessBlockUnblock", JSON.stringify(request), ack => {
    console.log("ack contactlessBlockUnblock", ack)
    const payload = JSON.parse(ack) 
    clearTimeout(event_blockunblock_request)
    app.ports.applyBlockUnblockRequestRecv.send(payload)
  })
  event_blockunblock_request = setTimeout(() => {
    app.ports.applyBlockUnblockRequestRecv.send({ error: "Não foi possível se comunicar com o plugin." })
  }, 10000)
}

// Every route change will check to open/close socket. If needed, port toggleSocket will be called
app.ports.toggleSocket.subscribe(toggle => {
  if (toggle === true) {
    console.log("> turn on socket")
    initSockets()
    console.log("< turn on socket")
  } else {
    console.log("> turn off socket")
    try { socket.disconnect()    } catch (e) {}
    try { hsmByPass.disconnect() } catch (e) {}
    try { hsmSocket.disconnect() } catch (e) {}
    socket = undefined
    hsmByPass = undefined
    hsmSocket = undefined
    app.ports.issueCard.unsubscribe(issueCardFn)
    app.ports.updateCard.unsubscribe(updateCardFn)
    app.ports.applyRefill.unsubscribe(applyRefillFn)
    app.ports.applyBlockUnblockRequest.unsubscribe(applyBlockUnblockRequestFn)
    console.log("< turn off socket")
    return
  }
  app.ports.issueCard.subscribe(issueCardFn)
  app.ports.updateCard.subscribe(updateCardFn)
  app.ports.applyRefill.subscribe(applyRefillFn)
  app.ports.applyBlockUnblockRequest.subscribe(applyBlockUnblockRequestFn)

  socket && socket.on("contactlessSerialNumber", serial => {
    if (typeof serial !== "string" || serial === "") return
    app.ports.onSmartCardValidate.send(serial)
  })

})



app.ports.logout.subscribe(() => {
  localStorage.removeItem("session")
})
app.ports.login.subscribe(({ token, permissions, permissionsCODE, address , cnpj , phone, issTax  }) => {
  const payloadJWT = token.split(".")[1]
  const decoded = Buffer.from(payloadJWT, 'base64').toString('utf8')
  const data = JSON.parse(decoded)
  const { iduser: id, metadata } = data
  const concessionaireId = (metadata || {}).concessionaireId;
  const session =
        { id,
          token,
          concessionaireId,
          userType: data.type,
          roles: permissions,
          permissions : permissionsCODE,
          expire: data.exp,
          concessionaires: data.concessionaires,
          userName: data.userName,
          address : address,
          cnpj : cnpj,
          phone : phone,
          issTax : issTax
        }
  localStorage.setItem('session', JSON.stringify(session));
  app.ports.receiveTokenData.send(session)
});

app.ports.printDocument.subscribe((url) => {
  printJS(url);
})

app.ports.printCard.subscribe(async data => {
  const { name, cpf, image } = data
  const printwindow = window.open('', 'PRINT', 'height=400,width=600');
  printwindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <style type="text/css" media="print">
          @media print {

            * {
              color: #000000;
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: Verdana, Geneva, Tahoma, sans-serif;
            }

            @page {
              margin: 0 0;
              size: 86mm 54mm;
            }

            @page :footer {
              display: none;
            }

            @page :header {
              display: none;
            }

            img {
              margin-top: 18mm;
              margin-left: 7mm;
              width: 17mm;
              height: 22mm;
            }

            .container {
              display: flex;
            }

            .container__title h1 {
              font-size: 12px;
            }

            .container__infos {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .container__infos h2 { 
              margin-top: 18mm;
            }

            .container__infos h2,
            .container__infos h3 {
              font-weight: 100;
              font-size: 12px;
              line-height: 23px;
              text-transform: uppercase;
            }

            .container__infos div:first-of-type {
              margin-right: 20px;
            }

            .container__img img{
              display: flex;
            }
          }
        </style>
        <script>
          function onloadimg () {
            window.document.close(); // necessary for IE >= 10
            window.focus(); // necessary for IE >= 10
            window.print();
            window.close();
          }
        </script>
      </head>
      <body>
      <div class="container">
        <div class="container__infos">
          <div class="container__img">
            <img onload="onloadimg()" src="${image}" alt="Avatar usuário(a)">
          </div>
          <div>
            <h2>${name}</h2>
            <h3>CPF: ${cpf}</h3>
          </div>
        </div>
      </div>
      </body>
    </html>
  `);
})

var localStream;

app.ports.useCamera.subscribe((videoId) => {
  // Wait Elm to render the element
  setTimeout(() => {
    var video = document.getElementById(videoId);

    if(video && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Not adding `{ audio: true }` since we only want video now
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            video.srcObject = stream;
            video.play();
          })
          .catch((e) => {
            app.ports.gotCameraPhoto.send({error : e.message});
          });
    } else {
      app.ports.gotCameraPhoto.send({error : "Nenhuma câmera encontrada"});
    }
  }, 60)
});

app.ports.takePhoto.subscribe((videoId) => {
  var video = document.getElementById(videoId);
  var elemWidth = video.offsetWidth;
  var elemHeight = video.offsetHeight;

  let mul = video.videoHeight / elemHeight;
  let sWidth = mul * elemWidth;
  let sHeight;
  if(sWidth <= video.videoWidth) {
    sHeight = mul * elemHeight;
  } else {
    mul = video.videoWidth / elemWidth;
    sWidth = mul * elemWidth;
    sHeight = mul * elemHeight;
  }
  var canvas = document.createElement('canvas');
  canvas.width = sWidth;
  canvas.height = sHeight;
  var context = canvas.getContext('2d');
  let sX = Math.abs(video.videoWidth - sWidth) / 2
  let sY = Math.abs(video.videoHeight - sHeight) / 2
  context.drawImage(video, sX, sY, sWidth, sHeight, 0, 0, sWidth, sHeight);
  var preview = canvas.toDataURL('image/jpeg', 0.9);
  canvas.toBlob((blob) => {
    var file = new File([blob], 'user-photo', { type: blob.type });
    video.pause();
    video.srcObject.getTracks()[0].stop();
    app.ports.gotCameraPhoto.send({file : file, preview : preview});
  });
});

app.ports.copy.subscribe((params) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(params);
    return;
  }
  navigator.clipboard.writeText(params.value).then(function() {
    app.ports.copySucceed.send(params.successMessage);
  }, function(err) {
    app.ports.copyFailed.send(params.errorMessage);
  });
});

function fallbackCopyTextToClipboard(params) {
  var textArea = document.createElement("textarea");
  textArea.value = params.value;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    if(successful) {
      app.ports.copySucceed.send(params.successMessage);
    } else {
      app.ports.copyFailed.send(params.errorMessage);
    }
  } catch (err) {
    app.ports.copyFailed.send(params.errorMessage);
  }

  document.body.removeChild(textArea);
}

app.ports.consoleError.subscribe(console.error);
// ------------------------------
// -----PORT SEND----------------
// ------------------------------
app.ports.sendPrintHTML.subscribe((htmlStr) => {

    const windowsPrint = window.open('', '_blank', 'width=800,height=600');
    windowsPrint.document.open();
    windowsPrint.document.write(htmlStr);
    windowsPrint.document.close();

    windowsPrint.onafterprint = function() {
      windowsPrint.close();
    };

    windowsPrint.print();
  }
)

app.ports.periodsToPositionTable.subscribe((params) => {
  console.log("init periodsToPositionTable", params.periods)
  const minutesInDay = 24 * 60;
  const minutesInBlock = 30;

// Função para converter minutos desde o início da semana para períodos [(dia, bloco)]
  function minutesToPeriods(ranges) {
    let periods = [];

    ranges.forEach(range => {
      const start = range[0];
      const end = range[1];
      let currentMinute = start;

      while (currentMinute < end) {
        const day = Math.floor(currentMinute / minutesInDay);
        const block = Math.floor((currentMinute % minutesInDay) / minutesInBlock);
        periods.push([day, block]);
        currentMinute += minutesInBlock;
      }
    });

    return periods;
  }
  const outputPeriods = minutesToPeriods(params.periods);
  //FIXME - Remove this
  console.log("outputPeriods", outputPeriods);

  app.ports.periodsToPositionTableSucceed.send({id : params.id, periods : outputPeriods});

})
//
// app.ports.positionTableToPeriodSeconds.subscribe((params => {
//   const minutesInDay = 24 * 60; // 1440
//   const minutesInBlock = 30;
//
// // Função para converter um período (dia, bloco) em minutos desde o início da semana
//   function periodToMinutes(period) {
//     const [day, block] = period;
//     return day * minutesInDay + block * minutesInBlock;
//   }
//
// // Função para agrupar períodos contínuos e converter em intervalos de tempo
//   function groupAndConvert(periods) {
//     // Ordena os períodos
//     periods.sort((a, b) => {
//       const minutesA = periodToMinutes(a);
//       const minutesB = periodToMinutes(b);
//       return minutesA - minutesB;
//     });
//
//     // Agrupa os períodos contínuos
//     let grouped = [];
//     let currentGroup = [];
//
//     periods.forEach((period, index) => {
//       if (index === 0 || periodToMinutes(period) === periodToMinutes(periods[index - 1]) + minutesInBlock) {
//         currentGroup.push(period);
//       } else {
//         grouped.push(currentGroup);
//         currentGroup = [period];
//       }
//     });
//     if (currentGroup.length > 0) grouped.push(currentGroup);
//
//     // Converte os grupos em intervalos de minutos, removendo o -1 do cálculo do final
//     return grouped.map(group => {
//       const start = periodToMinutes(group[0]);
//       const end = periodToMinutes(group[group.length - 1]) + minutesInBlock;
//       return [start, end];
//     });
//   }
//
//    const selectedPeriods =
//       params.positionsTable
//
//   const rangesOfMinutes = groupAndConvert(selectedPeriods);
//
//   app.ports.positionTableToPeriodSecondsSucceed.send({id : params.id, periods : rangesOfMinutes});
// }))

// Configura o drag-and-drop com SortableJS
app.ports.outgoingOrder.subscribe(() => {
  const tableBody = document.querySelector('#draggable-table tbody');
  console.log("tableBody:", tableBody)
  Sortable.create(tableBody, {
    animation: 150,
    ghostClass: 'dragging',
    onEnd: (evt) => {
      // Pega a nova ordem dos IDs
      const newOrder = Array.from(tableBody.children).map((row) => row.id);
      // Envia a ordem para o Elm
      app.ports.incomingOrder.send(newOrder);
    },
  });
});